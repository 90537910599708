<template>
    <!-- ***** Subscribe Area Starts ***** -->
    <div class="subscribe">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="section-heading">
            <h2>Yenilik ve Güncellemelerden Haberdar Ol</h2>
            <span>Bildirim sistemimize kayıt olarak hizmet ve fiyat güncellemelerinden haberdar olaiblirsiniz.</span>
          </div>
          <form id="subscribe" action="" method="get">
            <div class="row">
              <div class="col-lg-5">
                <fieldset>
                  <input name="name" type="text" id="name" placeholder="İsminiz" required="true">
                </fieldset>
              </div>
              <div class="col-lg-5">
                <fieldset>
                  <input name="email" type="text" id="email" pattern="[^ @]*@[^ @]*" placeholder="Mail Adresiniz" required="true">
                </fieldset>
              </div>
              <div class="col-lg-2">
                <fieldset>
                  <button type="submit" id="form-submit" class="main-dark-button">
                    <i class="fa fa-paper-plane"></i>
                  </button>
                </fieldset>
              </div>
            </div>
          </form>
        </div>
        <div class="col-lg-4">
          <div class="row">
            <div class="col-6">
              <ul>
                <li>
                  Lokasyon:<br>
                  <span>Muğla</span>
                </li>
                <li>
                  Telefon:<br>
                  <span>
                    <a href="tel:+95418722234">+90 541 872 2234</a>
                  </span>
                </li>
                <li>
                  Hizmet Alanı:<br>
                  <span>Tüm Türkiye</span>
                </li>
              </ul>
            </div>
            <div class="col-6">
              <ul>
                <li>
                  Çalışma Saatleri:<br>
                  <span>10:30 - 17:00 Hafta içi</span>
                </li>
                <li>
                  E-Posta:<br>
                  <span>
                    <a href="mailto:info@virtuozsoftware.com">
                      info@virtuozsoftware.com
                    </a>
                  </span>
                </li>
                <li>
                  Sosyal Medya:<br>
                  <span>
                    <a href="https://www.facebook.com/virtuozsoftware/" target="_blank">Facebook</a>, 
                    <a href="https://www.instagram.com/virtuozsoftware/" target="_blank">Instagram</a>, 
                    <a href="https://www.linkedin.com/company/76531120" target="_blank">Linkedin</a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ***** Subscribe Area Ends ***** -->
</template>
<script>
export default {
  name: "VSubscribe",
};
</script>