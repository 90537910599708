<template>
  <AppHeader></AppHeader>

  <router-view />

  <Subscribe></Subscribe>

  <AppFooter></AppFooter>
</template>
<script>
import { mapActions } from "vuex";

import AppHeader from "@/components/app/Header.vue";
import Subscribe from "@/components/app/Subscribe.vue";
import AppFooter from "@/components/app/Footer.vue";

export default {
  name: "App",
  components: {
    AppHeader,
    Subscribe,
    AppFooter,
  },
  data(){
    return {
      socialMediaBannerDescriptionHtml: "",
    };
  },
  mounted(){
    this.getAllFixedTexts();
    this.getAllFixedPhotos();
    this.getAllInstagramPosts();
  },
  methods: {
    ...mapActions({
      getAllFixedTexts: "getAllFixedTexts",
      getAllFixedPhotos: "getAllFixedPhotos",
      getAllInstagramPosts: "getAllInstagramPosts",
    }),
  },
};
</script>
