export const VServices = {
    state() {
        return {
            services: [],
        };
    },
    getters: {
        getAllServices(state) {
            return state.services;
        },
        getFeaturedServices(state) {
            return state.services.filter((service) => service.featured && !service.emphasized).slice(0, 4);
        },
        getEmphasizedServices(state) {
            return state.services.find((service) => service.emphasized);
        },
        getIsThereEmphasizedService(state) {
            return state.services.some((service) => service.emphasized);
        }
    },
    mutations: {
        setAllServices(state, services) {
            if(!services || services.length === 0) {

                state.services = [
                    {
                        id: 1,
                        name: "Yazılım Danışmanlığı",
                        shortDescription: "Proje adımlarınızı ve kullanacağınız teknolojileri birlikte planlayalım",
                        description: "Frontend, backend, fullstack yazılım danışmanlığı hizmeti",
                        htmlContent: "<p>Frontend, backend, fullstack yazılım danışmanlığı hizmeti</p>",
                        imageUrl: "assets/images/services/yazilim-danismanligi.png",
                        enabled: true,
                        featured: true,
                        emphasized: true,
                    },
                    {
                        id: 2,
                        name: "Yazılım Geliştirme",
                        shortDescription: "Proje fikirlerinizi sıfırdan birlikte geliştirelim.",
                        description: "İhtiyaç duyduğunuz projeleri analiz edip sıfırdan geliştirebiliriz.",
                        htmlContent: "<p>Frontend, backend, fullstack yazılım geliştirme hizmeti</p>",
                        imageUrl: "assets/images/services/yazilim-gelistirme.png",
                        enabled: false,
                        featured: true,
                    },
                    {
                        id: 3,
                        name: "Yazılım Testi",
                        shortDescription: "Projelerinizi test edip hataları tespit edelim.",
                        description: "Projelerinizin işleyişini, hatalarını ve performansını test edebiliriz.",
                        htmlContent: "<p>Frontend, backend, fullstack yazılım test hizmeti</p>",
                        imageUrl: "assets/images/services/yazilim-testi.png",
                        enabled: false,
                        featured: true,
                    },
                    {
                        id: 4,
                        name: "Yazılım Desteği",
                        shortDescription: "Yürüttüğünüz projelerde destek olalım.",
                        description: "Geliştirmekte olduğunuz projelere yazılım geliştirici desteğinde bulunabiliriz.",
                        htmlContent: "<p>Frontend, backend, fullstack yazılım destek hizmeti</p>",
                        imageUrl: "assets/images/services/yazilim-destegi.png",
                        enabled: false,
                        featured: true,
                    },
                    {
                        id: 5,
                        name: "Yazılım Bakımı",
                        shortDescription: "Projelerinizin bakım ve güncellemelerini yapalım.",
                        description: "Var olan projelerinizin güncelleştirmelerini ve performans kayıplarını giderebiliriz.",
                        htmlContent: "<p>Frontend, backend, fullstack yazılım bakım hizmeti</p>",
                        imageUrl: "assets/images/services/yazilim-bakimi.png",
                        enabled: false,
                        featured: true,
                    },
                ];

                return;
            }

            state.services = services;
        },
    },
    actions: {
        async getAllServices({ commit }) {
            commit("setAllServices", []);

            return {
                isSuccess: true,
            };
        },
    },
};
