export const VPrograms = {
  state() {
      return {
          programs: [],
      };
  },
  getters: {
      getAllPrograms(state) {
          return state.programs;
      },
      getFeaturedPrograms(state) {
        return state.programs.filter((x) => x.featured);
    },
  },
  mutations: {
      setAllPrograms(state, programs) {
          if(!programs || programs.length === 0) {
              state.programs = [
                {
                  id: 1,
                  name: "Pro Mental",
                  shortDescription: "Sipariş takip programı",
                  description: "Restoranlar, kafeler, pastaneler ve benzeri işletmeler için sipariş takip programı. Müşteri siparişlerini alabilir, hazırlık aşamalarını takip edebilir ve sipariş durumlarını güncelleyebilirsiniz. Müşteri siparişlerini alabilir, hazırlık aşamalarını takip edebilir ve sipariş durumlarını güncelleyebilirsiniz.",
                  htmlContent: "<h1>Pro Mental</h1><p>Restoranlar, kafeler, pastaneler ve benzeri işletmeler için sipariş takip programı. Müşteri siparişlerini alabilir, hazırlık aşamalarını takip edebilir ve sipariş durumlarını güncelleyebilirsiniz. Müşteri siparişlerini alabilir, hazırlık aşamalarını takip edebilir ve sipariş durumlarını güncelleyebilirsiniz.</p>",
                  imageUrl: "assets/images/programs/pro-mental.png",
                  price: "17,000 TL",
                  enabled: true,
                  featured: true,
                  orderNo: 1,
                },
                {
                  id: 2,
                  name: "Mini Mental",
                  shortDescription: "Mobil Sipariş takip programı",
                  description: "Restoranlar, kafeler, pastaneler ve benzeri işletmeler için sipariş takip programı. Müşteri siparişlerini alabilir, hazırlık aşamalarını takip edebilir ve sipariş durumlarını güncelleyebilirsiniz. Müşteri siparişlerini alabilir, hazırlık aşamalarını takip edebilir ve sipariş durumlarını güncelleyebilirsiniz.",
                  htmlContent: "<h1>Mini Mental</h1><p>Restoranlar, kafeler, pastaneler ve benzeri işletmeler için sipariş takip programı. Müşteri siparişlerini alabilir, hazırlık aşamalarını takip edebilir ve sipariş durumlarını güncelleyebilirsiniz. Müşteri siparişlerini alabilir, hazırlık aşamalarını takip edebilir ve sipariş durumlarını güncelleyebilirsiniz.</p>",
                  imageUrl: "assets/images/programs/mini-mental.png",
                  price: "0 TL",
                  enabled: true,
                  featured: true,
                  orderNo: 1,
                },
                {
                  id: 2,
                  name: "Stok Takip Programı",
                  shortDescription: "Stok takip programı",
                  description: "İşletmenizin stoklarını takip edebileceğiniz program. Stoklarınızı giriş yapabilir, çıkış yapabilir ve stok durumlarınızı güncelleyebilirsiniz. Stoklarınızı giriş yapabilir, çıkış yapabilir ve stok durumlarınızı güncelleyebilirsiniz.",
                  htmlContent: "<h1>Stok Takip Programı</h1><p>İşletmenizin stoklarını takip edebileceğiniz program. Stoklarınızı giriş yapabilir, çıkış yapabilir ve stok durumlarınızı güncelleyebilirsiniz. Stoklarınızı giriş yapabilir, çıkış yapabilir ve stok durumlarınızı güncelleyebilirsiniz.</p>",
                  imageUrl: "assets/images/programs/stok-takip.png",
                  price: "16,000 TL / Yıl",
                  enabled: true,
                  featured: true,
                  orderNo: 1,
                },
              ];
              return;
          }

          state.programs = programs;
      },
  },
  actions: {
      async getAllPrograms({ commit }) {
          commit("setAllPrograms", []);

          return {
              isSuccess: true,
          };
      },
  },
};
