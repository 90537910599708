import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import { VServices } from "./virtuoz/ServicesStore";
import { VGeneral } from "./virtuoz/GeneralStore";
import { VFixedTexts } from "./virtuoz/FixedTextsStore";
import { VInstagramPosts } from "./virtuoz/InstagramPostsStore";
import { VFixedPhotos } from "./virtuoz/FixedPhotosStore";
import { VReadyWebsites } from "./virtuoz/ReadyWebsitesStore";
import { VPrograms } from "./virtuoz/ProgramsStore";

const store = createStore({
    modules: {
      VServices: VServices,
      VGeneral: VGeneral,
      VFixedTexts: VFixedTexts,
      VFixedPhotos: VFixedPhotos,
      VInstagramPosts: VInstagramPosts,
      VReadyWebsites: VReadyWebsites,
      VPrograms: VPrograms,
    },
    plugins: [createPersistedState()],
});

export default store;
