<template>
    <!-- Header -->
  <header class="header-area header-sticky">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <nav class="main-nav">

            <!-- Logo Start -->
            <a href="index.html" class="logo">
              <img :src="baseUrl + 'assets/images/logo.png'">
            </a>
            <!-- Logo End -->

            <!-- Menu Start -->
            <ul class="nav">
              <li>
                <router-link to="/">Anasayfa</router-link>
              </li>
              <li>
                <router-link to="/ready-websites">Hazır Siteler</router-link>
              </li>
              <li>
                <router-link to="/programs">Programlar</router-link>
              </li>
              <li>
                <router-link to="/services">Hizmetler</router-link>
              </li>
              <li class="submenu">
                <a :class="getVirtuozMenuActive ? 'router-link-active router-link-exact-active': ''">
                  Virtüöz
                </a>
                <ul>
                  <li>
                    <router-link to="/about">Hakkımızda</router-link>
                  </li>
                  <li>
                    <router-link to="/terms">Sözleşmeler</router-link>
                  </li>
                  <li>
                    <router-link to="/contact">İletişim</router-link>
                  </li>
                </ul>
              </li>
              <li class="submenu">
                <a :class="getInformationActive ? 'router-link-active router-link-exact-active': ''">
                  Bilgi
                </a>
                <ul>
                  <li>
                    <router-link to="/help">Yardım</router-link>
                  </li>
                  <li>
                    <router-link to="/faq">Sıkça Sorulan Sorular</router-link>
                  </li>
                  <li>
                    <router-link to="/installation">Kurulum</router-link>
                  </li>
                </ul>
              </li>
            </ul>
            <a class='menu-trigger'>
              <span>Menu</span>
            </a>
            <!-- Menu End -->
          </nav>
        </div>
      </div>
    </div>
  </header>
  <!-- Header End -->
</template>
<script>
export default {
  name: "App-Header",
  computed: {
    getVirtuozMenuActive() {
      const currentPageName = this.$route.name;
      return currentPageName === 'about' || currentPageName === 'contact' || currentPageName === 'terms';
    },
    getInformationActive() {
      const currentPageName = this.$route.name;
      return currentPageName === 'help' || currentPageName === 'faq' || currentPageName === 'installation';
    },
  },
  data() {
    return {
      baseUrl: window.location.origin + '/',
    };
  },
};
</script>