<template>
  <div class="home">
    <MainBanner></MainBanner>

    <ReadyWebsites></ReadyWebsites>

    <Programs></Programs>

    <ExploreArea></ExploreArea>

    <SocialArea></SocialArea>
  </div>
</template>

<script>
// @ is an alias to /src
import MainBanner from '@/components/home/MainBanner.vue';

import ReadyWebsites from '@/components/home/ReadyWebsites.vue';
import Programs from '@/components/home/Programs.vue';

import ExploreArea from '@/components/home/ExploreArea.vue';
import SocialArea from "@/components/home/SocialArea.vue";

export default {
  name: 'HomeView',
  components: {
    MainBanner,
    ReadyWebsites,
    Programs,
    ExploreArea,
    SocialArea
  }
}
</script>
