export const VGeneral = {
    state() {
        return {
            data: {},
        };
    },
    getters: {
    },
    mutations: {
        setAllData(state, data) {
            if(!data || data.length === 0) {
                state.data = {
                    siteName: "Virtüöz Software",
                    description: "Virtüöz Software, özel yazılım hizmetleri, web tasarımı, web yazılımı, seo, e-ticaret sistemleri ve yazılım danışmanlığı hizmetleri sunmaktadır.",
                };

                return;
            }

            state.data = data;
        },
    },
    actions: {
        async getAllData({ commit }) {
            commit("setAllData", []);

            return {
                isSuccess: true,
            };
        },
    },
};
