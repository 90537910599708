import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ContactView from '@/views/ContactView.vue'

import ServiceDetailView from '../views/ServiceDetailView.vue'
import ReadyWebsiteDetail from '@/views/ReadyWebsiteDetail.vue'

const routes = [
  {
    path: '/',
    name: 'default',
    component: HomeView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },

  {
    path: '/service',
    name: 'service',
    component: HomeView
  },
  {
    path: '/service-detail/:id',
    name: 'service-detail',
    component: ServiceDetailView
  },
  
  {
    path: '/web-sites',
    name: 'web-sites',
    component: HomeView
  },
  {
    path: '/website-detail/:id',
    name: 'website-detail',
    component: ReadyWebsiteDetail
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
