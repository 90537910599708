export const VFixedTexts = {
    state() {
        return {
            texts: [],
        };
    },
    getters: {
        getShortAboutUsHtml(state) {
            return state.texts.find(x => x.primaryKey === 'shortAboutUsHtml')?.content;
        },
        getSocialMediaBannerDescriptionHtml(state){
            return state.texts.find(x => x.primaryKey === 'socialMediaBannerDescriptionHtml')?.content;
        },
    },
    mutations: {
        setAllFixedTexts(state, texts) {
            if(!texts || texts.length === 0) {
                state.texts = [
                    {
                        id: 1,
                        primaryKey: "shortAboutUsHtml",
                        content: `
                        <h2>Hizmetlerimizi Keşfedin</h2>
                        <span>
                            Virtüöz Software, yenilikçi çözümlerle işletmelerin dijital dönüşümünü hızlandırmayı ve iş süreçlerini optimize etmeyi amaçlayan bir yazılım şirketidir. Müşterilerimize sunduğumuz çeşitli hizmetlerle işletmelerin ihtiyaçlarını karşılamak için teknolojiyi kullanarak verimliliği artırıyoruz. 
                        </span>
                        <div class="quote">
                            <i class="fa fa-quote-left"></i>
                            <p>Müşterilerine yazılım çözümlerinden önce kendi Ar-Ge projelerine karşı danışman olma fikrini amacı olarak benimsemiş Virtüöz, sektörün en saygın şirketi olma yolunda emin adımlarla ilerliyor.</p>
                        </div>
                        <p>
                            Virtüöz Software olarak, her bir müşterimize özel çözümler sunuyoruz. İşletmelerin ihtiyaçlarını anlamak ve en uygun teknolojiyi kullanarak onları desteklemek, temel prensibimizdir. Müşterilerimizin başarısı bizim başarımızdır. Hizmetlerimizle ilgili daha fazla bilgi edinmek ve işletmeniz için en uygun çözümleri keşfetmek için bize ulaşın.
                        </p>
                        <div class="main-border-button">
                            <a href="/contact">İletişim</a>
                        </div>
                        `
                    },
                    {
                        id: 2,
                        primaryKey: "homePageAboutUsText1",
                        content: "Daha Hızlı",
                    },
                    {
                        id: 3,
                        primaryKey: "homePageAboutUsText2",
                        content: "Daha Güvenli",
                    },
                    {
                        id: 4,
                        primaryKey: "homePageAboutUsText3",
                        content: "Daha Güncel",
                    },
                    {
                        id: 4,
                        primaryKey: "homePageAboutUsText4",
                        content: "Daha Kaliteli",
                    },
                    {
                        id: 5,
                        primaryKey: "socialMediaBannerDescriptionHtml",
                        content: `İşletmenizin dijital dönüşümünde lider çözüm ortağınız! Yazılım danışmanlığı, QR menü, restoran takip ve depo yönetimi hizmetleriyle işinizi geliştirin. Takip etmek için bizi ziyaret edin! 
                        <a target="_blank" href="https://www.instagram.com/virtuozsoftware/">#VirtüözSoftware</a>`,
                    }
                ];

                return;
            }

            state.texts = texts;
        },
    },
    actions: {
        async getAllFixedTexts({ commit }) {
            commit("setAllFixedTexts", []);

            return {
                isSuccess: true,
            };
        },
        async getTextByPrimaryKeys({ state }, primaryKey) {
            return state.texts.find(x => primaryKey === x.primaryKey).content;
        }
    },
};
