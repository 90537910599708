<template>
  <!-- ***** Programs ***** -->
  <section class="section carousel-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="section-heading">
            <h2>Programlar</h2>
            <span>İhtiyacınıza yönelik hazırlanmış programlar.</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="owl-carousel-item owl-carousel">
            <div v-for="program in programs" :key="program.id" class="item">
              <div class="thumb">
                <div class="hover-content">
                  <ul>
                    <li>
                      <router-link :to="'/program-detail/' + program.id ">
                        <i class="fa fa-eye"></i>
                      </router-link>
                    </li>
                  </ul>
                </div>
                <img :src="program.imageUrl" :alt="program.name">
              </div>
              <div class="down-content">
                <h4>{{ program.name }}</h4>
                <span>{{ program.price }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ***** Programs ***** -->
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "VPrograms",
  computed: {
    ...mapGetters({
      programs: "getFeaturedPrograms",
    }),
  },
  mounted(){
    this.getAllPrograms();
  },
  methods: {
    ...mapActions({
      getAllPrograms: "getAllPrograms",
    }),
  },
}
</script>