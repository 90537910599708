export const VReadyWebsites = {
  state() {
      return {
          readyWebsites: [],
      };
  },
  getters: {
      getAllReadyWebsites(state) {
          return state.readyWebsites;
      },
      getFeaturedReadyWebsites(state) {
        return state.readyWebsites.filter((readyWebsite) => readyWebsite.featured);
    },
  },
  mutations: {
      setAllReadyWebsites(state, readyWebSites) {
          if(!readyWebSites || readyWebSites.length === 0) {

              state.readyWebsites = [
                  {
                      id: 1,
                      name: "Web Paketi 1",
                      shortDescription: "Web paketi 1 hizmeti",
                      description: "Web paketi 1 hizmeti",
                      htmlContent: "<p>Bu web sitesi üzerinden rezervasyon yapabilir, blog yazıları okuyabilir, hakkımızda sayfasını inceleyebilir ve iletişim bilgilerimize ulaşabilirsiniz.</p>",
                      imageUrl: "assets/images/readyWebsites/web-site-1.png",
                      price: "9,000 TL",
                      enabled: true,
                      featured: true,
                      orderNo: 1,
                  },
                  {
                      id: 2,
                      name: "Web Paketi 2",
                      shortDescription: "Web paketi 2 hizmeti",
                      description: "Web paketi 2 hizmeti",
                      htmlContent: "<p>Bu web sitesi üzerinden rezervasyon yapabilir, blog yazıları okuyabilir, hakkımızda sayfasını inceleyebilir ve iletişim bilgilerimize ulaşabilirsiniz.</p>",
                      imageUrl: "assets/images/readyWebsites/web-site-2.png",
                      price: "9,000 TL",
                      enabled: true,
                      featured: true,
                      orderNo: 2,
                  },
                  {
                      id: 3,
                      name: "Web Paketi 3",
                      shortDescription: "Web paketi 3 hizmeti",
                      description: "Web paketi 3 hizmeti",
                      htmlContent: "<p>Bu web sitesi üzerinden rezervasyon yapabilir, blog yazıları okuyabilir, hakkımızda sayfasını inceleyebilir ve iletişim bilgilerimize ulaşabilirsiniz.</p>",
                      imageUrl: "assets/images/readyWebsites/web-site-3.png",
                      price: "9,000 TL",
                      enabled: true,
                      featured: true,
                      orderNo: 3,
                  },
                  {
                      id: 4,
                      name: "Web Paketi 4",
                      shortDescription: "Web paketi 4 hizmeti",
                      description: "Web paketi 4 hizmeti",
                      htmlContent: "<p>Bu web sitesi üzerinden rezervasyon yapabilir, blog yazıları okuyabilir, hakkımızda sayfasını inceleyebilir ve iletişim bilgilerimize ulaşabilirsiniz.</p>",
                      imageUrl: "assets/images/readyWebsites/web-site-4.png",
                      price: "9,000 TL",
                      enabled: true,
                      featured: true,
                      orderNo: 4,
                  },
              ];

              return;
          }

          state.readyWebSites = readyWebSites;
      },
  },
  actions: {
      async getAllReadyWebsites({ commit }) {
          commit("setAllReadyWebsites", []);

          return {
              isSuccess: true,
          };
      },
  },
};
