<template>
    <!-- Footer Start -->
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-lg-3">
                    <div class="first-item">
                        <div class="logo">
                            <img src="assets/images/white-logo.png" alt="hexashop ecommerce templatemo">
                        </div>
                        <ul>
                            <li><a href="#">Halkapınar, 1203/11. Sk. 5 -7/72, 35190 Konak/İzmir</a></li>
                            <li><a href="mailto:info@virtuozsoftware.com">info@virtuozsoftware.com</a></li>
                            <li><a href="tel:+905418722234">+90 541 872 2234</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3">
                    <h4>Virtüöz <i><small>Software</small></i></h4>
                    <ul>
                        <li>
                            <router-link to="/service">Hizmetler</router-link>
                        </li>
                        <li>
                            <router-link to="/program">Programlar</router-link>
                        </li>
                        <li>
                            <router-link to="/web-sites">Hazır Web Siteleri</router-link>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-3">
                    <h4>Hızlı Linkler</h4>
                    <ul>
                        <li>
                            <router-link to="/home">Anasayfa</router-link>
                        </li>
                        <li>
                            <router-link to="/about">Hakkımızda</router-link>
                        </li>
                        <li>
                            <router-link to="/terms">Sözleşmeler</router-link>
                        </li>
                        <li>
                            <router-link to="/contact">İletişim</router-link>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-3">
                    <h4>Yardım &amp; Bilgi</h4>
                    <ul>
                        <li>
                            <router-link to="/help">Yardım</router-link>
                        </li>
                        <li>
                            <router-link to="/faq">Sıkça Sorulan Sorular</router-link>
                        </li>
                        <li>
                            <router-link to="/installation">Kurulum</router-link>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-12">
                    <div class="under-footer">
                        <p>Copyright © 2022 Virtüöz Software, tüm hakları gizlidir.</p>
                        <ul>
                            <li>
                                <a href="https://www.facebook.com/virtuozsoftware/" target="_blank">
                                    <i class="fa fa-facebook"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/company/76531120" target="_blank">
                                    <i class="fa fa-linkedin"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/virtuozsoftware/" target="_blank">
                                    <i class="fa fa-instagram"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <!-- Footer End -->
</template>
<script>
export default {
    name: "App-Footer",
    data() {
        return {
            baseUrl: window.location.origin + '/',
        };
    },
};
</script>