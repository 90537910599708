<template>
  <!-- ***** Ready Web Sites ***** -->
  <section class="section carousel-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="section-heading">
            <h2>Hazır Web Siteleri</h2>
            <span>İhtiyacınıza uygun birini beğenin, hızlıca yayına alalım.</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="owl-carousel-item owl-carousel">
            <div v-for="webSite in featuredReadyWebsites" :key="webSite.id" class="item">
              <div class="thumb">
                <div class="hover-content">
                  <ul>
                    <li>
                      <router-link :to="'/ready-website-detail/' + webSite.id ">
                        <i class="fa fa-eye"></i>
                      </router-link>
                    </li>
                  </ul>
                </div>
                <img :src="webSite.imageUrl" :alt="webSite.name">
              </div>
              <div class="down-content">
                <h4>{{ webSite.name }}</h4>
                <span>{{ webSite.price }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- ***** Ready Web Sites ***** -->
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ReadyWebsites",
  computed: {
    ...mapGetters({
      featuredReadyWebsites: "getFeaturedReadyWebsites",
    }),
  },
  mounted(){
    this.getAllReadyWebsites();
  },
  methods: {
    ...mapActions({
      getAllReadyWebsites: "getAllReadyWebsites",
    }),
  },
}
</script>